import React, { SVGProps } from 'react';

const SvgNoStroller = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			fill='currentColor'
			className='svg-icon'
			{...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M8.1 5.27A7.09 7.09 0 0110 5c.29 0 .58.02.86.05L9.49 6.67 8.1 5.27zm6.9 6.9V8.66l-1.61 1.89L15 12.17zM12.17 15l-1.39-1.39L9.6 15h2.57z'
				opacity={0.3}
			/>
			<path d='M8 20c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm7-11.34v3.51l2 2v-7.9c.58-.68.97-1.27 1.65-1.27.77 0 1.35.66 1.35 1.48V7h2v-.52C22 4.56 20.52 3 18.65 3c-1.66 0-2.54 1.27-3.18 2.03l-3.5 4.11 1.42 1.42L15 8.66zm4.78 13.95l-1.91-1.91c-.29.76-1.01 1.3-1.87 1.3-1.1 0-2-.9-2-2 0-.86.54-1.58 1.3-1.87L14.17 17H7.43c-.85 0-1.31-1-.76-1.65l2.69-3.16-7.97-7.97L2.8 2.81l7.86 7.86 1.42 1.42 9.11 9.11-1.41 1.41zM12.17 15l-1.39-1.39L9.6 15h2.57zM10 5c.29 0 .58.02.86.05L9.49 6.67l1.42 1.42L14.3 4.1C13.03 3.4 11.56 3 10 3c-1.23 0-2.4.25-3.47.7L8.1 5.27A7.09 7.09 0 0110 5z' />
		</svg>
	);
}

export default SvgNoStroller;
