import { combineReducers } from "redux";
//import dreamsReducer from "./dreams";
import userReducer from "./user";
// import customersReducer from "./customers";
// import categoryReducer from "./category";
// import ui_wpReducer from "./UI/ui_wp";
// import messagesReducer from "./messages";
// import imessagesReducer from "./messages/imessages";
// import audioReducer from "./messages/audio";
// import videoReducer from "./messages/video";
// import documentReducer from "./messages/document";
// import imagesReducer from "./messages/image";
// import locationReducer from "./messages/location";
// import msgtextsReducer from "./messages/msgtext";
// import contactssReducer from "./messages/contacts";
import listmessagesReducer from  "./wp/listmessages";

export default combineReducers({
  //dreams: dreamsReducer,
  user: userReducer,
  // customers: customersReducer,
  // category: categoryReducer,
  // ui_wp: ui_wpReducer,
  // messages: messagesReducer,
  // imessages: imessagesReducer,
  // audios: audioReducer,
  // videos: videoReducer,
  // documents: documentReducer,
  // images: imagesReducer,
  // locations: locationReducer,
  // msgtexts: msgtextsReducer,
  // contactss: contactssReducer,
  listmessages: listmessagesReducer,
  //report: reportReducer,
});
