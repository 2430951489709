import React, { SVGProps } from 'react';

const SvgMms = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			fill='currentColor'
			className='svg-icon'
			{...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M4 17.17L5.17 16H20V4H4v13.17zM8.5 9.5l2.5 3.01L14.5 8l4.5 6H5l3.5-4.5z'
				opacity={0.3}
			/>
			<path d='M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H5.17L4 17.17V4h16v12zm-5.5-8L11 12.51 8.5 9.5 5 14h14z' />
		</svg>
	);
}

export default SvgMms;
