import React, { FC } from 'react';
import PropTypes from 'prop-types';
import WhatsBot from '../assets/logos/WhatsBOT.png';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	const h1Styles = {
		enableBackground:"new 0 0 841.9 595.3"
	};
	return (
		 

	 
		<img  className='p-2'
			height={width !== 2155 && !!width ? width * (854 / 2155) : height}
			src={WhatsBot}
  ></img>




	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
