import React, { SVGProps } from 'react';

const SvgEarbuds = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			fill='currentColor'
			className='svg-icon'
			{...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M7 6c0-.55-.45-1-1-1s-1 .45-1 1v1h1c.55 0 1-.45 1-1zM17 18c0 .55.45 1 1 1s1-.45 1-1v-1h-1c-.55 0-1 .45-1 1z'
				opacity={0.3}
			/>
			<path d='M16 3c-2.76 0-5 2.24-5 5v8c0 1.66-1.34 3-3 3s-3-1.34-3-3V9h.83C7.44 9 8.89 7.82 9 6.21A3 3 0 006.2 3C4.44 2.89 3 4.42 3 6.19V16c0 2.76 2.24 5 5 5s5-2.24 5-5V8c0-1.66 1.34-3 3-3s3 1.34 3 3v7h-.83c-1.61 0-3.06 1.18-3.17 2.79A3 3 0 0017.8 21c1.76.12 3.2-1.42 3.2-3.18V8c0-2.76-2.24-5-5-5zM5 6c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1H5V6zm14 12c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1h1v1z' />
		</svg>
	);
}

export default SvgEarbuds;
