import React, { SVGProps } from 'react';

const SvgRealEstateAgent = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			fill='currentColor'
			className='svg-icon'
			{...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M10.5 9.44l5.32 1.99c1.24.46 2.21 1.41 2.74 2.57H19V7.5L14 4 9 7.5V9h.33l1.17.44zm4-2.44h1v1h-1V7zm0 2h1v1h-1V9zm-2-2h1v1h-1V7zm0 2h1v1h-1V9zM3 13h2v7H3v-7zm16.9 5.57l-5.93 1.84L7 18.48V13h1.61l5.82 2.17c.34.13.57.46.57.83 0 0-1.99-.05-2.3-.15l-2.38-.79-.63 1.9 2.38.79c.51.17 1.04.26 1.58.26H19c.39 0 .74.23.9.56z'
				opacity={0.3}
			/>
			<path d='M15.5 7h-1v1h1V7zm-2 0h-1v1h1V7zm2 2h-1v1h1V9zm-2 0h-1v1h1V9zM21 6.5V14h-2V7.5L14 4 9 7.5V9H7V6.5l7-5 7 5zM19 16h-2c0-1.2-.75-2.28-1.87-2.7L8.97 11H1v11h6v-1.44l7 1.94 8-2.5v-1c0-1.66-1.34-3-3-3zM3 20v-7h2v7H3zm10.97.41L7 18.48V13h1.61l5.82 2.17c.34.13.57.46.57.83 0 0-1.99-.05-2.3-.15l-2.38-.79-.63 1.9 2.38.79c.51.17 1.04.26 1.58.26H19c.39 0 .74.23.9.56l-5.93 1.84z' />
		</svg>
	);
}

export default SvgRealEstateAgent;
