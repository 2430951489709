import React, { SVGProps } from 'react';

const SvgFactCheck = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			fill='currentColor'
			className='svg-icon'
			{...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M4 19h16V5H4v14zm9.41-8.25l1.41 1.42L17.99 9l1.42 1.42L14.82 15 12 12.16l1.41-1.41zM5 7h5v2H5V7zm0 4h5v2H5v-2zm0 4h5v2H5v-2z'
				opacity={0.3}
			/>
			<path d='M20 3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H4V5h16v14z' />
			<path d='M19.41 10.42L17.99 9l-3.17 3.17-1.41-1.42L12 12.16 14.82 15zM5 7h5v2H5zM5 11h5v2H5zM5 15h5v2H5z' />
		</svg>
	);
}

export default SvgFactCheck;
