export const homeMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
		
	},
};


export const FlowChart = {
	FlowChart: {
		id: 'FlowCHart',
		text: 'Flow Chart',
		path: 'WPFlowChart-pages/WPFlowChart',
		icon: 'AccountTree',
		subMenu: null,
	},
};


export const WPChatMenu = {
	WPChat: {
		id: 'WPChat',
		text: 'Chat History',
		path: 'WPChat-pages/WPChat',
		icon: 'Quickreply',
		subMenu: null,
	},
};
export const WPSurvey = {
	WPSurvey: {
		id: 'WPSurvey',
		text: 'Survey',
		path: 'WPSurvey-pages/WPSurvey',
		icon: 'BarChart',
		subMenu: null,
	},
};
export const WPContactList = {

	ContactList: {
		id: 'ContactsList',
		text: 'Contact Directory',
		path: 'WPContactList-pages/WPContactList',
		icon: 'BackupTable',
		 
		}
	
};
export const demoPages = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
};


export const DocPages = {
	APIDoc: {
		id: 'APIDoc',
		text: 'API Document',
		path: 'API-pages/API',
		icon: 'Api',
	},
	ChatBot: {
		id: 'ChatBot',
		text: 'Chat Bot',
		path: 'ChatBot-pages/ChatBot',
		icon: 'Quickreply',
	},
	/* signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	}, */
};

export const companyMsgList = {
	msgList: {
		id: 'msgList',
		text: 'List Of Company Messages',
		icon: 'Extension',
	},
	messagesKind: {
		id: 'messageList',
		text: 'Messages',
		path: 'messages-list',
		icon: 'BackupTable',
		subMenu: {
			InteractiveList: {
				id: 'InteractiveList',
				text: 'Interactive List',
				path: 'InteractiveList-pages/InteractiveList',
				icon: 'FormatListBulleted',
			},
			InteractiveButtons: {
				id: 'InteractiveButtons',
				text: 'Interactive Buttons',
				path: 'InteractiveButtons-pages/InteractiveButtons',
				icon: 'SmartButton',
			},
			text: {
				id: 'text',
				text: 'Text',
				path: 'text-pages/text',
				icon: 'ViewHeadline',
			},
			freetext: {
				id: 'freetext',
				text: 'Free Text Write',
				path: 'text-pages/freetext',
				icon: 'WrapText',
			},
			image: {
				id: 'image',
				text: 'Image',
				path: 'image-pages/image',
				icon: 'PhotoLibrary',
			},
			audio: {
				id: 'audio',
				text: 'Audio',
				path: 'audio-pages/audio',
				icon: 'VolumeUp',
			},
			video: {
				id: 'video',
				text: 'Video',
				path: 'video-pages/video',
				icon: 'Videocam',
			},
			document: {
				id: 'document',
				text: 'Document',
				path: 'Document-pages/document',
				icon: 'FilePresent',
			},
			location: {
				id: 'location',
				text: 'Location',
				path: 'Location-pages/location',
				icon: 'Map',
			},
			contact: {
				id: 'contact',
				text: 'Contact',
				path: 'Contact-pages/contact',
				icon: 'ContactPage',
			},

		},
	},




};



export const layoutMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'List Of Company Messages',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	messagesKind: {
		id: 'messageList',
		text: 'Messages',
		path: 'messages-list',
		icon: 'BackupTable',
		subMenu: {
			imageList: {
				id: 'imageList',
				text: 'Images List',
				path: 'messages-list/images-list',
				icon: 'Image',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	Chat: {
		id: 'Chat',
		text: 'Chat',
		path: 'Chat-types',
		icon: 'Vertical Split',

	},
};

export const productsMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
